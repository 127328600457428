
import { defineComponent, onBeforeMount, ref } from 'vue'
import { notification } from 'ant-design-vue'

import profileService from '../../../services/profile'
import courseService from '../../../services/course'
import commonServices from '../../../services/common'
import appServices from '../../../services/appSettings'

export default defineComponent({
  name: 'Dashboard',
  setup (props, { emit }) {
    const statistic = ref()
    const courses = ref()
    const youtube = ref([])
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getOpenCourse = async () => {
      try {
        const clientId = commonServices.getClientId()
        const responce = await courseService.getMyCourse(clientId)
        courses.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getClientStatistic = async () => {
      try {
        const clientId = commonServices.getClientId()
        const responce = await profileService.getClientStatistics(clientId)
        statistic.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getAppSettingsBySlug = async (slug) => {
      try {
        const responce = await appServices.getBySlug(slug)
        const youtubeURLS = responce.data.value.split(',')
        let i = 1
        for (const url of youtubeURLS) {
          youtube.value['url' + i] = 'https://www.youtube.com/embed/' + url.split('/')[3]
          i++
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onBeforeMount(() => {
      emit('updateMenu')
      getClientStatistic()
      getOpenCourse()
      getAppSettingsBySlug('client-dashboard-youtube-url')
    })
    return {
      statistic,
      courses,
      youtube
    }
  }
})
