import axios from 'axios'

export default {
  getBySlug (slug) {
    return axios.get(process.env.VUE_APP_APP_SETTINGS + '/by-slug/' + slug)
  },

  addOrUpdate (data) {
    return axios.post(process.env.VUE_APP_APP_SETTINGS + '/add-update', data)
  }
}
